import React from 'react';

class Calltoaction extends React.Component {
    render() {
        return <>
                      <b>To get started call us now (08) 9300 3361</b>
                </>;
    }
}

export default Calltoaction;
