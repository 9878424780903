import React from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './css/quote.scss';
import emailjs from 'emailjs-com';
function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_g4fje0f', 'template_mqihm93', e.target, 'user_KfUF6GOpJsOkvUoyUaIw8')
      .then((result) => {
          console.log(result.text);
          document.getElementById("quote-formSet").reset();
          document.getElementById("msg").innerHTML="Thank you! Your quotation request has been received! Will reply you soon.";
      }, (error) => {
          console.log(error.text);
      });
  }
class Quoteform extends React.Component {
    render() {
        return <Container>
            <Row>
                <Col>

                

                    <Form id="quote-formSet" onSubmit={sendEmail}>
                        <Row className="mb-3 mt-3">

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>Name</Form.Label>
                                <Form.Control name="name" placeholder="Enter Name" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email"  type="email" placeholder="Enter email" required/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridState">
                                <Form.Label>Services</Form.Label>
                                <Form.Select name="services"  defaultValue="Carpet Cleaning" required>
                                    <option>Carpet Cleaning</option>
                                    <option>Floor Cleaning</option>
                                    <option>Window Cleaning</option>
                                    <option>Upholstery Cleaning</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control  name="message"  as="textarea" rows={3} placeholder="Enter Details" required />
                            </Form.Group>

                        </Row>

                        <Button variant="primary" type="submit">Submit</Button>

                        
                    </Form>
                    <div id="msg"></div>
                </Col>
            </Row>
        </Container>;

    }
}

export default Quoteform;

