import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


library.add(fas, fab);

class Fontawesome extends React.Component {
    render() {
        return <FontAwesomeIcon icon={this.props.icon} />;
    }
}

export default Fontawesome;