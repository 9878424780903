import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fontawesome from './Fontawesome';

import './css/topbar.scss';

class Topbar extends React.Component {
    render() {
        return <div className="topbar">
            <Container>
                <Row>
                    <Col sm={6} className="topBar">
                        Home & Office Cleaner 
                    </Col>
                    <Col sm={6} className="text-end">
                        <Link to="/window-cleaning"><Fontawesome icon={['fab', 'facebook']} /></Link>
                        <Link to="/window-cleaning"><Fontawesome icon={['fab', 'twitter']} /></Link>
                    </Col>
                </Row>
            </Container>
        </div>;
    }
}

export default Topbar;