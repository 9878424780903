
import React from 'react';
import { Route } from "react-router-dom";

import Home from './Home';
import WindowCleaning from './WindowCleaning';
import CarpetCleaning from './CarpetCleaning';
import UpholsteryCleaning from './UpholsteryCleaning';
import FloorCleaning from './FloorCleaning';
import Testimonials from './Testimonials';
import WhoWeAre from './WhoWeAre';
import Faq from './Faq';
import GetQuote from './GetQuote';
import Contact from './Contact';


const FirstScreen = () => {
    return (
        <>
            <Route exact path="/"> <Home /> </Route>
            <Route exact path="/home"> <Home /> </Route>
            <Route exact path="/window-cleaning"> <WindowCleaning /> </Route>
            <Route exact path="/carpet-cleaning"> <CarpetCleaning /> </Route>
            <Route exact path="/floor-cleaning"> <FloorCleaning /> </Route>
            <Route exact path="/upholstery-cleaning"> <UpholsteryCleaning /> </Route>
            <Route exact path="/testimonials"> <Testimonials /> </Route>
            <Route exact path="/who-we-are"> <WhoWeAre /> </Route>
            <Route exact path="/faq"> <Faq /> </Route>
            <Route exact path="/get-a-quote"> <GetQuote /> </Route>
            <Route exact path="/contact"> <Contact /> </Route>
            
        </>
    );

};

export default FirstScreen;
