import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagetitle from './Pagetitle';
import Quoteform from './Quoteform';

class Contact extends React.Component {
    render() {
        return <>
        <Pagetitle pagetitle="Contact" />
        <Container>
            <Row>
                <Col sm={5}>
                    <h3>Perth Cleanup</h3>
                    <p>24 Monger Street, Perth
                    WA 6000, Australia</p>
                    <hr />

                    <p>Phone: (08) 9300 3361</p>
                    <hr />

                    <p>E-mail: info@perthcleanups.com</p>


                </Col>
                <Col sm={7}>
                    <Quoteform />
                </Col>
            </Row>
        </Container></>;
    }
}

export default Contact;
