import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagetitle from './Pagetitle';
import Calltoaction from './Calltoaction';

class WindowCleaning extends React.Component {
    render() {
        return <>
        <Pagetitle pagetitle="Window Cleaning" />
        <Container>
            <Row>
                <Col><div>
                    <h3 className="mb-3">As a professional residential window cleaning service with over 10 years of experience Perth Cleanup. knows how to get the job done quickly and at a cost-effective price.</h3>
                    <p>The next time you see a small sunbeam; take a look. All the little things you see reflecting light is what makes your windows dirty. This dirt can actually etch its way into the glass if left for an extended period of time. Hence, it is important to have your windows cleaned regularly otherwise the integrity of the glass is compromised.</p>
                    <p>Perth Cleanup can organize a cleaning schedule so that your house gives the best of impressions. We are fully insured and bonded so our clients can have peace of mind that their investments are fully protected. Give us a call! We will come to your house and give you a free estimate.</p>
                    <Calltoaction />

                </div>
                </Col>
            </Row>
        </Container></>;
    }
}

export default WindowCleaning;
