import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main.scss';
import ReactDOM from 'react-dom';
import './index.css';
import MainPg from './MainPg';

import Topbar from './Topbar';
import Navigation from './Navigation';
import Footer from './Footer';

import { BrowserRouter } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
ReactDOM.render(
  <BrowserRouter>
    <header>
      <Topbar />
      <Navigation />
    </header>
    <main>
      <MainPg />
    </main>
    <footer>
      <Footer />
    </footer>
  </BrowserRouter>,
  document.getElementById('page')

);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
