import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagetitle from './Pagetitle';
import Calltoaction from './Calltoaction';

class UpholsteryCleaning extends React.Component {
    render() {
        return <>
        <Pagetitle pagetitle="Upholstery Cleaning" /><Container>
            <Row>
                <Col><div>
                    <h3 className="mb-3">We offer professional upholstery cleaning in Perth. Our professional upholstery cleaners use a hot water extraction process that is also commonly called steam cleaning.</h3>
                    <p>Sofas and chairs can be expensive household items and regular professional cleaning will help to extend their life. Our chosen method is to use hot water extraction. It’s certainly one of the best proven methods for deep upholstery cleaning. Our operatives are fully trained to recognise the different fabrics and, if necessary, they will do a colour fast test and pile test before they begin cleaning. Of  course, some fabrics can only be cleaned by a dry cleaning process and staff will advise you of this on the day.</p>
                    <p>How To Clean Leather Furniture</p>
                    <p>Cleaning leather is not an easy task. We clean leather upholstery by hand before applying a conditioning cream. This helps preserve the leather. </p>
                    <p>50% off Upholstery Cleaning</p>
                    <p>We are currently offering 50% off our standard pricing for upholstery cleaning in Perth and the surrounding areas.</p>
                    <p>Please email or call and speak to one of our friendly team who will give you a competitive quote straight away. We can then schedule an appointment  to carry out the work where an experienced operative will come to your home and professionally clean your upholstery. To see some of our work  please visit our our gallery. </p>
                    <p>Our professional upholstery cleaners Perth have over 15 years experience, are fully insured and offer a fantastic level of customer service.</p>
                    <Calltoaction />
                </div>
                </Col>
            </Row>
        </Container></>;
    }
}

export default UpholsteryCleaning;
