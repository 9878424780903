import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagetitle from './Pagetitle';
import Fontawesome from './Fontawesome';
import Calltoaction from './Calltoaction';

class FloorCleaning extends React.Component {
    render() {
        return <>
        <Pagetitle pagetitle="Floor Cleaning" /><Container>
            <Row>
                <Col><div>
                    <h3 className="mb-3">Line up for cleaner tile. Cleaning tile floors by hand is hard work. It’s messy, uncomfortable—and impossible to get the deep-clean results that come with professional tile and grout cleaning.</h3>
                    <p>That’s because grout, which is a porous material, collects dirt, grime and spills, often discoloring the surface. Regular mopping and spot cleaning don’t reach the hidden dirt that lies deep within the pores of your grout lines. Our professional tile and grout cleaning service restores the luster to your floors.</p>
                    <p>At Perth Cleanup, our floor tile cleaners achieve far better results than any hand cleaning could ever do. Our trained technicians begin by inspecting your floor to determine the best treatment process and the proper cleaning agent. Next, our state-of-the-art tile steam cleaners yield a high-pressure water and vacuum combination that extracts dirt from deep within the tile and the pores of your grout. Although it’s safe and gentle on your floors, our unique tile and grout cleaning wand is highly effective. Seal the Deal</p>
                    <p>Once the tile is clean, you have the option to add a finishing coat of our clear seal or color seal, which protects your grout and keeps it looking great. Perth Cleanup’s clear sealant penetrates the grout surface to create a protective barrier against spills and permanent stains. It also ensures that future tile and grout cleanings yield the best possible results.</p>
                    <p>The color grout sealant is our premier grout restoration service. When grout is permanently stained and even the best cleaning methods can’t reverse the discoloration, our exclusive system restores uniform color and provides a waterproof seal.</p>
                    <p>Like always, Perth Cleanup makes tile and grout cleaning easy and hassle-free. An average-size room (up to 300 square feet) takes only 30 to 60 minutes to clean. Times vary based on tile size, amount of soil in the grout lines, and room configuration. If you choose to include sealant, it typically takes about 2 to 3 hours to complete. We recommend waiting 30 minutes before walking on the floor so the sealant can dry properly. Because it takes 24 hours to cure completely, avoid any liquid from coming in contact with the sealant during that time.</p>
                    <p><strong>Tips for Tile and Grout Cleaning:</strong></p>
                    <ul className="fa-ul">
                        <li><Fontawesome icon="chevron-right" /> To ensure the lasting beauty of your tile floor and to preserve the life of sealants, use Perth Cleanup Professional Neutral Tile &amp; Grout Cleaner.</li>
                        <li><Fontawesome icon="chevron-right" /> Always vacuum/sweep before mopping to eliminate as much dry soil as possible.</li>
                        <li><Fontawesome icon="chevron-right" /> Always rinse after you mop.</li>
                        <li><Fontawesome icon="chevron-right" /> Avoid bleach and other harsh chemical cleaners that might break down sealants.</li>
                    </ul>
                    <Calltoaction />
                </div>
                </Col>
            </Row>
        </Container></>;
    }
}

export default FloorCleaning;
