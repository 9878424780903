import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Calltoaction from './Calltoaction';
import Pagetitle from './Pagetitle';


class WhoWeAre extends React.Component {
    render() {
        return <>
        <Pagetitle pagetitle="who we are" />
        <Container>
            <Row>
                <Col><div>
                    <h3>Perth Cleanup Service was started in 1990 by Jimmy that had a dream to build the highest quality and most trusted name in professional house or office cleaning services in the Perth areas.</h3>
                    <p>Their main premise was to provide you, the client, with a unique house or office cleaning experience that would leave you stress-free, happy, and with a vacation feeling that lacks the daily cleaning chores.</p>
                    <p>Having very strong work ethics and knowing exceptional cleaning methods developed in Europe, Jimmy started the business by cleaning homes throughout the communities of Perth. Over time, based on countless referrals and superb retention of clients, the cleaning service was extended to Perth surrounded areas.</p>
                    <p>As of today, Kathy is the sole owner of Perth Cleanup Service. Many people ask why the name of the business is Perth Cleanup’s but the owner is Jimmy. Well… about 10 years ago, Perth Cleanup made a decision to move to Perth to join her family there, and we miss her a lot. However, we are keeping our tradition of being family owned and operated, and our goal still stays the same since the business inception – to surpass your expectations every time we clean your home or office! We pride ourselves on earning an outstanding reputation for quality service which is second to none, and we look forward to proving that to you.</p>
                    <Calltoaction />

                </div>
                </Col>
            </Row>
        </Container></>;
    }
}

export default WhoWeAre;
