import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import slidimg1 from './images/a1.jpg';
import slidimg2 from './images/a2.jpg';
import slidimg3 from './images/a3.jpg';
import slidimg4 from './images/a4.jpg';

class Slider extends React.Component {
    render() {
        return <section><Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={slidimg1}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h2>Carpet Cleaning</h2>
                    <p>State-of-the-art carpet care</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={slidimg2}
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <h2>Window Cleaning</h2>
                    <p>Professional residential window cleaning service.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={slidimg3}
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <h2>Upholstery Cleaning</h2>
                    <p>Professional upholstery cleaners use a hot water extraction process</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={slidimg4}
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <h2>Floor Cleaning</h2>
                    <p>Professional Neutral Tile & Grout Cleaner.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel></section>;
    }
}
export default Slider;