import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './css/pagetitle.scss';



class Pagetitle extends React.Component {
    render() {
        return <section className="mb-5" id="page_title">
            <Container>
                <Row className="mb-5">
                    <Col sm={12}>
                        <h1 className="page_title">{this.props.pagetitle}</h1>
                    </Col>
                </Row>

            </Container></section>
            ;
    }
}

export default Pagetitle;

