import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagetitle from './Pagetitle';
import Accordion from 'react-bootstrap/Accordion'
import './css/faq.scss';
class Faq extends React.Component {
  render() {
    return <>
      <Pagetitle pagetitle="FAQ" />
      <Container>
        <Row>
          <Col>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Are you bonded and insured?</Accordion.Header>
                <Accordion.Body>
                  Absolutely. We are bonded and insured.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Will I always get the same cleaner?</Accordion.Header>
                <Accordion.Body>
                  We will do our best to keep your cleaning specialist consistent. However, if s/he is unavailable on your chosen date/time, we will send a fantastic replacement!

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>What is the cost of my cleaning?</Accordion.Header>
                <Accordion.Body>
                  Please visit our service pages for hourly and flat rates as well as special package pricing.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>How do I pay for the service?</Accordion.Header>
                <Accordion.Body>
                  For residential clients, we accept MasterCard, Visa, and American Express. For our commercial clients, we also accept checks.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Should I tip my cleaner?</Accordion.Header>
                <Accordion.Body>
                  Tipping is greatly appreciated and is typically 15-20% of the regular cleaning price. Please leave your tip in cash and clearly labeled in an envelope. We do not accept credit card tips at this time.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>What should I do when you arrive?</Accordion.Header>
                <Accordion.Body>
                  Please inform your cleaning specialist of any delicate items, broken pieces, or other notable changes in your home since his/her last visit. If you’re present, we’ll request your final approval of the service before we leave.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>What if something in my home gets broken?</Accordion.Header>
                <Accordion.Body>
                  If you’re not home, we will leave the item in clear view with a note. The office will then notify you about completing a damage report to have it replaced. This rarely happens, but we’re 100% honest when it does and take full responsibility. The cleaning specialists are not penalized for any mishaps – accidents happen.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>How do you access my home?</Accordion.Header>
                <Accordion.Body>
                  That is completely up to you. We are happy to accommodate your schedule in any way we can. Many of our clients provide us with an extra key, but some prefer to be present during the cleaning.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>What should I have available to my cleaner?</Accordion.Header>
                <Accordion.Body>
                  Please have a mop, broom, bucket, and vacuum available prior to your cleaning. If you need any or all of these items, we are happy to provide them. Just add them in your basket at checkout or call us 72 hours prior to your appointment to request them.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>What is your cancellation policy?</Accordion.Header>
                <Accordion.Body>
                  If you need to cancel or reschedule your appointment, please call us at least 48 hours in advance. If you miss this courtesy window, we’ll need to charge you 100% of the entire cleaning fee. The charge is non-refundable.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>What if my cleaning falls on a holiday?</Accordion.Header>
                <Accordion.Body>
                  Some of our cleaners choose to work on holidays. We will contact you in advance to see whether you’d like to move forward with the cleaning or reschedule.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>How do I provide feedback on my cleanings?</Accordion.Header>
                <Accordion.Body>
                  After your cleaning, we’ll send you an email requesting your feedback. We will also call you if you’d prefer to discuss your cleaning experience over the phone. You’re also welcome to submit your feedback to us via this feedback icon.

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container></>;
  }
}

export default Faq;
