import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagetitle from './Pagetitle';
import Calltoaction from './Calltoaction';
import Fontawesome from './Fontawesome';

class CarpetCleaning extends React.Component {
    render() {
        return <>
        <Pagetitle pagetitle="Carpet Cleaning" /><Container>
            <Row>
                <Col><div>
                    <h3 className="mb-3">With more than six decades of experience in state-of-the-art carpet care, Perth Cleanup is well positioned to provide you with the best carpet cleaning service available.</h3>
                    <p>We are the biggest carpet cleaning service in the nation, and our iconic yellow vans are a familiar sight in neighborhoods everywhere. Today, we know that our success as the best carpet cleaning provider in the country is based on a few solid principles that we enthusiastically endorse:</p>
                    <ul className="fa-ul">
                        <li><Fontawesome icon="chevron-right" /> Consistent, quality carpet care experiences with guaranteed customer satisfaction</li>
                        <li><Fontawesome icon="chevron-right" /> Responsive and reputable carpet cleaning service available 24/7</li>
                        <li><Fontawesome icon="chevron-right" /> Superior equipment that we manufacture ourselves</li>
                        <li><Fontawesome icon="chevron-right" /> Highly-trained technicians who are professionally screened and never subcontracted</li>
                        <li><Fontawesome icon="chevron-right" /> Geographic scope that reaches 95 percent of the U.S. population</li>
                    </ul>
                    <p>There are many reasons why Perth Cleanup provides the best carpet cleaning service in the business. Not the least of these is our exclusive hot-water extraction method. We inject hot, soft water and our proprietary cleaning solution into your carpet. Then our magic wand loosens and lifts the dirt and soil from the carpet, without the abrasive brushing of other cleaning methods. And, drying time is faster since 95 percent of the moisture is suctioned out of the carpet. That means no leftover soapy residue. You say goodbye to dirt, allergens, dust mites and other microscopic pollutants that collect in your carpet.
                        Professional Deep Clean from the Experts</p>
                    <p>At Perth Cleanup, we also know that every carpet cleaning situation is unique. That’s why we begin our carpet cleaning service with a complete analysis of your carpet, noting any heavily-soiled or high-traffic areas. Then our cleaning specialists use our highly-effective hot-water extraction method to remove ground-in dirt and debris that may or may not be visible to the naked eye. Go Ahead! Walk on It!</p>
                    <p>Our powerful, truck-mounted vacuum system extracts most of the moisture from your carpet, but it may still be damp to the touch. It’s okay to walk on it—just not in your street shoes. You can accelerate the drying time with fans or air conditioning. Just be sure to leave the protective pads in place until the carpet and furniture are completely dry to avoid any possible transfer of color. And be careful not to slip when leaving damp, carpeted areas.</p>
                    <p>Perth Cleanup’s carpet cleaning service is quick, convenient and hassle-free. Your home is back to normal in a flash. We even return the furniture to its original position. It’s one more reason Perth Cleanup’s carpet cleaning service sets the standard in high-quality carpet care.</p>
                    <Calltoaction />
                </div>
                </Col>
            </Row>
        </Container></>;
    }
}

export default CarpetCleaning;
