import React from 'react';
import './css/footer.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom'

import footerLogo from './images/perthcleanups-logo-footer.png';

class Footer extends React.Component {
    render() {
        return <Container>
            <Row>
                <Col sm={12} md={12} lg={6}>
                <p><Link className="pl-3" to="/data/">
                    <img className=" preload-me float-left" src={footerLogo} width="182" height="100" sizes="182px" alt="Perth Cleanup" /></Link>© 2021 Perth Cleanup. All rights reserved. </p>
                </Col>
                <Col sm={12} md={12} lg={6} className="text-end">
                    <p>Web Design &amp; Developed by <a target="_blank" rel="noreferrer" href="http://www.revolutioneye.com">Revolution Eye</a> </p>
                </Col>
            </Row>
        </Container>;
    }
}

export default Footer;
