import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Quoteform from './Quoteform';
import Pagetitle from './Pagetitle';


class GetQuote extends React.Component {
    render() {
        return <>
        <Pagetitle pagetitle="Get A Quote" /><section className="mt-5"><Container>
            <Row>
                <Col>
                    <Quoteform />
                </Col>
            </Row>
        </Container></section></>;
    }
}

export default GetQuote;
