import React from 'react';
import Slider from './Slider';
import NavBox from './NavBox';
import HomeContent from './HomeContent';
import Quote from './Quote';




class Home extends React.Component {
    render() {
        return <div>
            <Slider />
            <NavBox />
            <HomeContent />
            <Quote />
        </div>
    }
}

export default Home;
