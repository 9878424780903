import React from "react";
import navOne from './images/n1.jpg';
import navTwo from './images/n2.jpg';
import navThree from './images/n3.jpg';
import navFour from './images/n4.jpg';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './css/navbox.scss';



class Navigation extends React.Component {
    render() {
        return <section className="mt-5 navbox">
            <Container>
                <Row className="mb-5">
                    <Col sm={12}>
                        <h1 className="text-center display-3">Welcome to Perth Cleanup</h1>
                        <h3 className="lrg-hd text-center">Home &amp; Office Cleaner in Perth</h3>
                    </Col>
                </Row>
                <Row className="navigationboxes">
                    <Col sm={3} className="text-center">
                        <Link to="/window-cleaning"><img src={navOne} alt="Window Cleaning" /></Link>
                        <h4 className="mb-0">Window <span>Cleaning</span></h4> 

                    </Col>
                    <Col sm={3} className="text-center">
                        <Link to="/carpet-cleaning"><img src={navTwo} alt="Carpet Cleaning" /></Link>
                        <h4 className="mb-0">Carpet <span>Cleaning</span></h4> 

                    </Col>
                    <Col sm={3} className="text-center">
                        <Link to="/floor-cleaning"><img src={navThree} alt="Floor Cleaning" /></Link>
                        <h4 className="mb-0">Floor <span>Cleaning</span></h4> 

                    </Col>
                    <Col sm={3} className="text-center">
                        <Link to="/upholstery-cleaning"> <img src={navFour} alt="Upholstery Cleaning" /></Link>
                        <h4 className="mb-0">Upholstery <span>Cleaning</span></h4> 


                    </Col>
                </Row>
            </Container></section>
            ;
    }
}

export default Navigation;

