import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagetitle from './Pagetitle';
import Card from 'react-bootstrap/Card'
import Fontawesome from './Fontawesome';
class Testimonials extends React.Component {
    render() {
        return <>
            <Pagetitle pagetitle="Testimonials" />
            <Container>
                <Row>
                    <Col sm={4}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Absolutely fantastic service. Been using Perth Cleanup for 8 years now and can say they are the best and most professional carpet cleaners around. I would recommend speaking to Elaine as she is most helpful and can give a good deal.
                                </Card.Text>
                                <Card.Title><Fontawesome icon="user-tie" />  Deborah M</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    What can I say. Very courteous, promt, tidy, polite and generally a very nice person that attended to my cleaning needs. I can’t recommend them highly enough. I will use in the future. Thank you.
                                </Card.Text>
                                <Card.Title><Fontawesome icon="user-tie" />  Glenda A.</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    Absolutely great service! Just started using Perth Cleanup after another carpet cleaning company cancelled on me last minute and I called Perth Cleanup and they were able to book me in on short notice. The level of cleaning was brilliant and I was very happy with the results. Will definitely use it again!
                                </Card.Text>
                                <Card.Title><Fontawesome icon="user-tie" /> Satterstrom</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container></>;
    }
}

export default Testimonials;
