import React from "react";
import Quoteform from "./Quoteform";
import './css/quote.scss';
import { Container, Row, Col } from 'react-bootstrap';

class Quote extends React.Component {
    render() {
        return <section className="mt-5" id="quote">
            <Container>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={12} md={12} lg={6}>
                        <h1 className="text-center display-3">Get a Quote!</h1>
                        <Quoteform />
                    </Col>
                    <Col sm={3}></Col>
                </Row>
            </Container>

        </section>;

    }
}

export default Quote;

