import React from "react";
import './css/navigation.scss';
import { NavLink, Link } from 'react-router-dom'
import logo from './images/perthcleanups-logo.png';
import { Container, NavDropdown, Nav, Navbar } from 'react-bootstrap';
//import { Collapse, Popover, Toast, Tooltip, Alert, Modal, Dropdown } from 'react-bootstrap';


class Navigation extends React.Component {
    render() {
        return <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
            <Container>
                <Navbar.Brand><Link to="/home"><img className=" preload-me branding" src={logo} width="127" height="100" sizes="327px" alt="Perth Cleanup" /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="">
                        <NavLink className="nav-link" to="/home">Home</NavLink>
                        <NavDropdown title="About" id="collasible-nav-dropdown">
                            <li><NavLink to="/who-we-are">Who We Are</NavLink></li>
                            <li><NavLink to="/testimonials">Testimonials</NavLink></li>
                        </NavDropdown >
                        <NavDropdown title="SERVICES" id="collasible-nav-dropdown">
                            <li><NavLink to="/window-cleaning">Window Cleaning</NavLink></li>
                            <li><NavLink to="/carpet-cleaning">Carpet Cleaning</NavLink></li>
                            <li><NavLink to="/floor-cleaning">Floor Cleaning</NavLink></li>
                            <li><NavLink to="/upholstery-cleaning">Upholstery Cleaning</NavLink></li>
                        </NavDropdown >
                        <NavLink className="nav-link" to="/faq">FAQ</NavLink>
                        <NavLink className="nav-link" to="/get-a-quote">GET A QUOTE</NavLink>
                        <NavLink className="nav-link  last " to="/contact">CONTACT</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>;
    }
}

export default Navigation;

