import React from "react";
import Calltoaction from "./Calltoaction";
import { Container, Row, Col } from 'react-bootstrap';



class HomeContent extends React.Component {
    render() {
        return <section className="mt-5"><Container>
            <Row>
                <Col>
                    <h3>When it comes to how your home looks, you have high expectations.</h3>
                    <p>After all, your home or office is a reflection of you. Which is why you should leave your home or office cleaning to us, the professional home and office cleaning service that all of our customers would recommend to friends and family.</p>
                    <p>The experts at The Help Cleaning Services deliver quality, trust, professionalism, and attentiveness as a professional house cleaning company each and every day. Our absolute attention to detail means you can expect a house cleaning service that delivers perfection every time.</p>
                    <p>This is why choosing the right company for your all cleaning needs is extremely crucial. We are completely confident we will gain your trust and satisfaction from the very first time you call us. Make the call and we will make sure you get the right service at the right time.</p>
                    <Calltoaction />
                </Col>
            </Row>
        </Container></section>;
    }
}

export default HomeContent;

